<template>
  <BlockContainer>
    <div
      class="p-10 md:p-20 flex flex-grow flex-col items-center justify-center"
    >
      <div class="mb-20 flex flex-wrap justify-center">
        <span
          v-for="{ colour } in data"
          :key="colour"
          v-text="colour.toUpperCase()"
          @click="activeColour = colour"
          class="cursor-pointer text-lg mx-4 whitespace-no-wrap"
          :class="{ underline: activeColour === colour }"
        />
      </div>
      <div v-for="diamond in data" :key="diamond.colour" class="w-full">
        <div
          class="flex flex-wrap items-center justify-center"
          :class="{
            'absolute opacity-0 pointer-events-none':
              activeColour !== diamond.colour
          }"
        >
          <CoreImage
            v-for="(image, i) in diamond.images"
            :key="i"
            :image="image"
            imgClass="w-full"
            class="image-size w-1/2 md:w-1/4"
          />
        </div>
        <div
          :class="{
            'absolute opacity-0 pointer-events-none':
              activeColour !== diamond.colour
          }"
          class="text-center mt-20"
        >
          <p class="text-xl">{{ diamond.quote }}</p>
          <p class="text-sm">{{ diamond.attribution }}</p>
        </div>
      </div>
    </div>
  </BlockContainer>
</template>

<script>
import { getDiamonds } from "@io";
import { BlockContainer } from "@c/Block";

export default {
  name: "Diamonds",

  components: {
    BlockContainer
  },

  data: () => ({
    data: null,
    activeColour: ""
  }),

  async created() {
    const response = await getDiamonds();
    this.data = response.ordering.map(diamond => diamond.fields);
    this.activeColour = this.data[0].colour;
  }
};
</script>

<style scoped>
.image-size {
  max-width: 200px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-leave-active {
  position: absolute;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
