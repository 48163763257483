var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BlockContainer',[_c('div',{staticClass:"p-10 md:p-20 flex flex-grow flex-col items-center justify-center"},[_c('div',{staticClass:"mb-20 flex flex-wrap justify-center"},_vm._l((_vm.data),function({ colour }){return _c('span',{key:colour,staticClass:"cursor-pointer text-lg mx-4 whitespace-no-wrap",class:{ underline: _vm.activeColour === colour },domProps:{"textContent":_vm._s(colour.toUpperCase())},on:{"click":function($event){_vm.activeColour = colour}}})}),0),_vm._l((_vm.data),function(diamond){return _c('div',{key:diamond.colour,staticClass:"w-full"},[_c('div',{staticClass:"flex flex-wrap items-center justify-center",class:{
          'absolute opacity-0 pointer-events-none':
            _vm.activeColour !== diamond.colour
        }},_vm._l((diamond.images),function(image,i){return _c('CoreImage',{key:i,staticClass:"image-size w-1/2 md:w-1/4",attrs:{"image":image,"imgClass":"w-full"}})}),1),_c('div',{staticClass:"text-center mt-20",class:{
          'absolute opacity-0 pointer-events-none':
            _vm.activeColour !== diamond.colour
        }},[_c('p',{staticClass:"text-xl"},[_vm._v(_vm._s(diamond.quote))]),_c('p',{staticClass:"text-sm"},[_vm._v(_vm._s(diamond.attribution))])])])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }